@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: BakbakOne-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0F0F0F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'BakbakOne-Regular';
  src: local('BakbakOne-Regular'), url(./fonts/BakbakOne-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(./fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ArchivoBlack-Regular';
  src: local('ArchivoBlack-Regular'), url(./fonts/ArchivoBlack-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-Bold';
  src: local('LeagueSpartan-Bold'), url(./fonts/LeagueSpartan-Bold.ttf) format('truetype');
}