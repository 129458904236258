html {
    overflow-y: scroll;
  }

* {
    padding:0;
    margin:0;
    border:0;

}
p.PPrivacyPolicy {
  font-family:'Lato-Regular';
  color: #E5E5E5;
  width:100%;
  font-size: 20px;
  text-align:left;
  margin: auto;
}

p.PPrivacyPolicy2 {
  font-family:'Lato-Regular';
  color: #E5E5E5;
  width:100%;
  font-size: 15px;
  max-width: 400px;
  text-align:left;
  margin: auto;
}

.PrivacyPolicy {
  background-color: #FFFFFF;
  padding: 50px;
  font-size: 25px;
  align-items: center;
  max-width:1025px;
  margin:auto;
}

.PrivacyPolicy2 {
  background-color: #FFFFFF;
  padding: 50px;
  font-size: 25px;
  align-items: center;
  width:350px;
  margin:auto;
}

h1.Header1PrivacyPolicy {
  font-family:'ArchivoBlack-Regular';
  color: #FFFFFF;
  font-size: 85px;
  padding-bottom: 25px;
}

h1.Header1PrivacyPolicy2 {
  font-family:'ArchivoBlack-Regular';
  color: #FFFFFF;
  text-align: center;
  margin:auto;
  font-size: 40px;
  padding-bottom: 25px;
  max-width: 400px;
}

h2.Header2PrivacyPolicy {
  font-family:'ArchivoBlack-Regular';
  color:#FFFFFF;
  font-size: 50px;
  padding-top:50px;
  padding-bottom:10px;
  width:100%;
  text-align:left;
  margin: auto;
}

h2.Header2PrivacyPolicy2 {
  font-family:'ArchivoBlack-Regular';
  color:#FFFFFF;
  font-size: 35px;
  padding-top:50px;
  max-width: 400px;
  padding-bottom:10px;
  width:100%;
  text-align:left;
  margin: auto;
}

.emailUs {
  color:#19E094;
}
.emailUs:visited {
  color:#19E094;
}
.emailUs:hover {
  color:#06D385;
}
.emailUs:active {
  color:#19E094;
}

#l12 {
  background-color: #FFFFFF
}

#l11 {
  background-color: #FFFFFF
}

#l13 {
  background-color: #FFFFFF
}

#l14 {
  background-color: #FFFFFF
}
#l15 {
  background-color: #FFFFFF
}
#l16 {
  background-color: #FFFFFF
}
#l17 {
  background-color: #FFFFFF
}
#l18 {
  background-color: #FFFFFF
}
#l19 {
  background-color: #FFFFFF
}